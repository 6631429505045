import { MouseEvent, useCallback } from 'react';
import { useNavigate as useRouterNavigate } from 'react-router-dom';

import useDi from 'common/lib/DependencyInjection/useDi';
import { NavigationTarget } from 'common/lib/Routing/types';
import { getFullPath, getFullUrl } from 'common/lib/Routing/utils/pathUtils';
import { LinkClickEvent } from 'common/modules/tealium/utils/tealiumEvents';

import { useTealiumNavigateClick } from 'common/modules/tealium/hooks/useTealiumClick';

export interface Options {
  customEventData?: Partial<LinkClickEvent>;
  event?: MouseEvent;
  replace?: boolean;
  state?: any;
  target?: '_blank' | 'blank' | 'window';
}

const useNavigate = () => {
  const di = useDi();
  const locale = di.get('core.locale');
  const navigate = useRouterNavigate();
  const tealiumClick = useTealiumNavigateClick();

  return useCallback(
    (to: NavigationTarget, options?: Options) => {
      const path = getFullPath(to, locale);
      tealiumClick({
        customEventData: options?.customEventData,
        path: getFullUrl(path, false),
        trigger: options?.event?.currentTarget,
      });

      if (path.startsWith('http')) {
        if (options?.target) {
          return window.open(path, options.target);
        } else if (options?.replace) {
          return window.location.replace(path);
        } else {
          return window.location.assign(path);
        }
      }

      navigate(path, options);
    },
    [locale, navigate, tealiumClick]
  );
};

export default useNavigate;
