const setDescription = (description: string): void => {
  const descriptionEl = window.document.querySelector('meta[name="description"]');
  if (
    typeof descriptionEl === 'object' &&
    (descriptionEl as HTMLElement | null)?.getAttribute('content') !== description
  ) {
    (descriptionEl as HTMLElement | null)?.setAttribute?.('content', description);
  }
};

export default setDescription;
