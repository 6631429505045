import { css, cx } from 'panda/css';
import { splitCssProps } from 'panda/jsx';
import { forwardRef } from 'react';

import { HTMLStyledProps } from 'panda/types';

export interface ImageProps extends Omit<HTMLStyledProps<'img'>, 'css'> {
  fetchpriority?: 'auto' | 'high' | 'low';
  intrinsicHeight?: number;
  intrinsicWidth?: number;
  imageSize?: 'illustration';
}

export const Image = forwardRef<HTMLImageElement, ImageProps>((props, ref) => {
  const [cssProps, { alt, className, intrinsicHeight, intrinsicWidth, imageSize, ...restProps }] = splitCssProps(props);

  return (
    <img
      alt={alt}
      className={cx(
        css({
          ...cssProps,
          ...(imageSize === 'illustration'
            ? { height: { base: '[75px]', sm: '[100px]' }, width: { base: '[96px]', sm: '[128px]' } }
            : {}),
        }),
        className
      )}
      height={intrinsicHeight}
      ref={ref}
      width={intrinsicWidth}
      {...restProps}
    />
  );
});

Image.displayName = 'Image';
