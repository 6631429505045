import { NavigationType, RouteMatch } from 'react-router-dom';
import { combineReducers, Reducer } from 'redux';

import { DependencyInjection } from 'common/lib/DependencyInjection/services/services';
import { NavigationTarget, RouteLoading } from 'common/lib/Routing/types';

import { Options } from 'common/lib/Routing/hooks/useNavigate';

import defaultGlobalTranslations from 'common/modules/translation/config/globalNameSpaces';
import GlobalState from 'common/stores/global';

interface TransitionOptions {
  matches: RouteMatch[];
  di: DependencyInjection;
  initialState?: Partial<GlobalState>;
  notFoundEvent?: () => void;
}

const loadComponentFiles = (matches: RouteMatch[]) =>
  Promise.all(
    matches.map(async (routeMatch) => {
      const { load, element } = routeMatch.route as RouteLoading;
      if (!element && load) {
        const file = await load();
        (routeMatch.route as RouteLoading).component = file.default;
      }
      const Component = (routeMatch.route as RouteLoading).component;
      if (Component) {
        routeMatch.route.element = <Component />;
      }
    })
  );

const replaceReducers = (matches: RouteMatch[], di: DependencyInjection, initialState?: Partial<GlobalState>) => {
  matches.forEach((match) => {
    const { component } = match.route as RouteLoading;
    const { registerReducers } = component || {};
    if (registerReducers) {
      const newReducers = registerReducers();
      const store = di.get('core.redux');
      const currentReducers = store.currentReducers || ({} as Record<string, Reducer>);
      store.currentReducers = { ...currentReducers, ...newReducers };
      if (initialState) {
        // @ts-ignore
        store.replaceReducer(combineReducers(injectInitialReducers(store.currentReducers, initialState)));
      } else {
        // @ts-ignore
        store.replaceReducer(combineReducers(store.currentReducers));
      }
    }
  });
};

const loadTranslations = async (matches: RouteMatch[], di: DependencyInjection) => {
  const nameSpaces = matches.reduce<string[]>((nameSpaces, match) => {
    const { loadTranslations } = (match.route as RouteLoading).component || {};
    if (loadTranslations) {
      return [...nameSpaces, ...loadTranslations()];
    }
    return nameSpaces;
  }, []);
  await di.get('core.translation').loadNamespaces([...defaultGlobalTranslations, ...new Set(nameSpaces)]);
};

export const loadBeforeTransition = async (
  matches: RouteMatch[],
  di: DependencyInjection,
  navigate: (to: NavigationTarget, options?: Options) => void
): Promise<{ shouldNavigate: boolean }> => {
  for (const match of matches) {
    const { component } = match.route as RouteLoading;
    const { beforeTransition } = component || {};

    if (beforeTransition) {
      const result = await beforeTransition({
        di,
        navigate: (to) => {
          navigate(to);
          return { shouldNavigate: true };
        },
        params: match.params,
      });

      if (typeof result === 'object' && result.shouldNavigate) {
        return { shouldNavigate: true };
      }
    }
  }

  return { shouldNavigate: false };
};

export const executeAfterLoad = async (matches: RouteMatch[], di: DependencyInjection) => {
  for (const match of matches) {
    const { component } = match.route as RouteLoading;
    const { afterLoad } = component || {};
    if (afterLoad) {
      await afterLoad({
        di,
        params: match.params,
      });
    }
  }
};

export const loadAfterTransition = async (matches: RouteMatch[], di: DependencyInjection) => {
  for (const match of matches) {
    const { component } = match.route as RouteLoading;
    const { afterTransition } = component || {};
    if (afterTransition) {
      await afterTransition({ di, params: match.params });
    }
  }
};

export const loadComponents = async (options: TransitionOptions) => {
  const { matches, di, initialState } = options;
  try {
    await loadComponentFiles(matches);
  } catch (ex) {
    options.notFoundEvent?.();
  }
  replaceReducers(matches, di, initialState);
  await loadTranslations(matches, di);
};

const injectInitialReducers = (reducers: Record<string, Reducer>, initialState: Partial<GlobalState>) =>
  Object.entries(reducers).reduce<Record<string, Reducer>>((finalReducers, [key, currentReducer]) => {
    if (typeof currentReducer === 'function') {
      finalReducers[key] = (state = initialState[key as keyof typeof initialState], action) =>
        currentReducer(state, action);
    }
    return finalReducers;
  }, {});

export const handleScroll = (navigationType: NavigationType) => {
  !!window && navigationType !== 'POP' && window.scrollTo(0, 0);
};
